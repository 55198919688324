import {
  Currency,
  FireScore,
  Industry,
  Nullable,
  PaginationParams,
  ServerChoice,
  SimpleDetails,
  WithId,
} from '@/core/interfaces/common';

import { User } from '@/features/Auth/interfaces';
import { Role } from '@/features/Employee/interfaces';
import { SimpleProjectManager } from '@/features/ProjectManager/interfaces';

interface Country {
  code: string;
  name: string;
}

interface SlackNotificationChannel {
  id: number;
  name: string;
  isPrivate: boolean;
}

export interface NpsScoring {
  id?: number;
  promoterScore?: number;
  happinessIndexScore?: number;
  motivationIndexScore?: number;
  npsScoreUpdatedAt?: string;
  happinessIndexUpdatedAt?: string;
  promoterScoreDescription?: string;
}

interface SimpleEmployee {
  id: string;
  email: string;
  fullName: string;
}

export interface ProjectMemberAggregated {
  roleId: number;
  shortName: string;
  amount: number;
}

export interface ProjectMember {
  dateFrom: string;
  dateTo: string;
  employee: SimpleEmployee;
  id: number;
  role: Role;
}

export enum CategoryStatus {
  CLOSED = 1,
  OPEN_EXTERNAL = 2,
  OPEN_INTERNAL = 3,
  TEMPLATE = 4,
  UNDEFINED = 5,
}

export interface SimpleProject extends SimpleDetails {
  archived: boolean;
  category: CategoryStatus;
  context: string;
  contextHistoryUpdatedAt: string;
  budgetPlanned: string;
  budgetUsed: string;
  startDate: string;
  endDate: string;
  currency: Currency;
  bio: Array<SimpleDetails>;
  processOwnership: Array<SimpleDetails>;
  processOwnershipComment: string;
  fireScore: Nullable<FireScore>;
  projectMembers: Array<ProjectMember>;
  projectMembersAggregated: Array<ProjectMemberAggregated>;
  riskUpdatedAt: string;
  country: Country;
  npsScore: NpsScoring;
  contactPersons: Array<SimpleDetails>;
  projectManagers: Array<SimpleProjectManager>;
  observers: Array<User>;
  industry: Industry;
  ownership: Industry;
  methodology: Industry;
  type: Industry;
  clientType: Industry;
  design: Industry;
  clientFeedback: string;
  clientFeedbackUpdatedAt: string;
  slackNotificationChannel: SlackNotificationChannel;
  canUpdateBudget: boolean;
}

export interface PatchProjectRequest {
  archived: boolean;
  fireScore?: number;
  context?: string;
  budgetPlanned: string;
  budgetUsed?: string;
  startDate: string;
  endDate: string;
  currency: Currency;
  bio?: Array<number>;
  observers: Array<string>;
  processOwnership: Array<number>;
  processOwnershipComment: string;
  projectMembers: Array<ProjectMember>;
  projectMembersAggregated: Array<number>;
  country: Country;
  contactPersons: Array<ProjectContactUser>;
  contactpeople: Array<ProjectContactUser>;
  npsScore: NpsScoring;
  projectManagers: Array<number>;
  fireScoreUpdatedAt?: string;
  name: string;
  id: number;
  industry: Industry;
  ownership: Industry;
  methodology: Industry;
  type: Industry;
  clientType: Industry;
  design: Industry;
  slackNotificationChannel?: SlackNotificationChannel;
  canUpdateBudget: boolean;
}

export interface PatchProjectPayload {
  id: number;
  data: Partial<PatchProjectRequest>;
}

export interface PatchNpsPayload extends WithId {
  payload: Partial<NpsScoring>;
}

export interface PatchProjectFirescoreRequest {
  firescore: number;
}
export interface PatchProjectFirescorePayload {
  id: number;
  data: PatchProjectRequest;
}

export type Project = SimpleProject;

export type ProjectOptions = {
  actions: {
    post: {
      country: {
        type: string;
        required: boolean;
        readOnly: boolean;
        label: string;
        choices: Array<ServerChoice>;
      };
    };
  };
}

export interface ProjectContactUser {
  company: string;
  email: string;
  name: string;
  phone: string;
  position: string;
}

export interface ProjectMemberForm {
  dateFrom: string;
  dateTo: string;
  employee: SimpleEmployee & number;
  role: Role & number;
}
export interface AddProjectPayload {
  name: string;
  bio: Array<number>;
  country: string;
  startDate: string;
  endDate: string;
  currency: string;
  budgetPlanned: number;
  contactPeople: Array<ProjectContactUser>;
  contactpeople: Array<ProjectContactUser>;
  processOwnership: Array<number>;
  processOwnershipComment?: Nullable<string>;
  projectMembers: Array<ProjectMemberForm>;
}

export interface HistoryContext {
  clientFeedback?: string;
  context: string;
  historyDate: string;
}

export interface HistoryContextPaginationParams extends PaginationParams {
  id: number;
}

export enum PROJECT_KEYS {
  name = 'name',
  country = 'country',
  startDate = 'startDate',
  endDate = 'endDate',
  currency = 'currency',
  budgetPlanned = 'budgetPlanned',
  contactPeople = 'contactpeople',
  processOwnership = 'processOwnership',
  processOwnershipComment = 'processOwnershipComment',
  projectMembers = 'projectmembers',
  industry = 'industry',
  methodology = 'methodology',
  type = 'type',
  clientType = 'clientType',
  design = 'design',
  ownership = 'ownership',
  slackNotificationChannel = 'slackNotificationChannel',
  canUpdateBudget = 'canUpdateBudget'
}

export enum CONTACT_PEOPLE_KEYS {
  company = 'company',
  email = 'email',
  name = 'name',
  phone = 'phone',
  position = 'position',
}

export enum PROJECT_MEMBER_KEYS {
  employee = 'employee',
  role = 'role',
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
}

export interface SingleFirescore {
  id: number;
  scoreName: string;
  riskScore: number;
}

export interface SingleFirescoreHistory {
  fireScore: SingleFirescore;
  historyDate: string;
  id: number;
}
